<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="dataList"
    :page.sync="page"
    :items-per-page="itemsPerPage"
    :loading="loading"
    loading-text="資料載入中..."
    no-data-text="暫無資料"
    hide-default-footer
    @page-count="pageCount = $event"
  )
    template(v-slot:item.number="{ item }")
      span(v-if="item !== editingItem") {{ item.number }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.number")
    template(v-slot:item.groupId="{ item }")
      span(v-if="item !== editingItem") {{ groupName(item.groupId) }}
      editGroup(v-if="item === editingItem" :value="item.groupId" @groupId="handleUpdateGroupId")
    template(v-slot:item.name="{ item }")
      span(v-if="item !== editingItem") {{ item.name }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.name")
    template(v-slot:item.phone="{ item }")
      span(v-if="item !== editingItem") {{ item.phone }}
      input(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.phone")
    template(v-slot:item.parmChurch="{ item }")
      span(v-if="item !== editingItem") {{ formatChurch(item.parmChurch) }}
      select(
        class="edit-component"
        v-if="item === editingItem"
        v-model="editingItem.parmChurch")
        option(v-for="(church, churchIndex) in clcList" :key="churchIndex" :value="church.value") {{ church.text }}
    template(v-slot:item.lastDedicationDate="{ item }")
      span(v-if="item !== editingItem") {{ item.lastDedicationDate }}
      input(
        class="edit-date-input"
        type="date"
        v-if="item === editingItem"
        v-model="editingItem.lastDedicationDate")
    template(v-slot:item.actions="{ item }" v-if="right.operate.allowEditRow")
      v-btn(
        v-if="item.id && editMode === 'edit' && item === editingItem"
        @click="updateItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 儲存
      v-btn(
        v-if="item.id && editMode === 'change' && item === editingItem"
        @click="changeItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 變更
      v-btn(
        v-if="item.id && item === editingItem"
        @click="cancelEditItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
      v-btn(
        v-if="item.id && item !== editingItem"
        @click="edit(item)"
        small
        outlined
        color="teal"
        class="mx-1 text-button") 編輯
      v-btn(
        v-if="item.id && item !== editingItem"
        @click="change(item)"
        small
        outlined
        color="indigo"
        class="mx-1 text-button") 變更
      v-btn(
        v-if="!item.id && item === editingItem"
        @click="addItem(item)"
        small
        dark
        color="cyan darken-4"
        class="mx-1 text-button") 新增
      v-btn(
        v-if="!item.id && item === editingItem"
        @click="cancelAddItem(item)"
        small
        dark
        color="yellow darken-4"
        class="mx-1 text-button") 取消
  v-pagination(
    v-model="page"
    :length="pageCount"
  )
</template>
<script>
import editGroup from "@/components/common/editGroup";
export default {
  name: "dedicationNumberSearchTable",
  props: {
    loading: {
      type: Boolean,
    },
    dataList: {
      type: Array,
    },
    addMode: {
      type: Boolean,
    },
  },
  components: {
    editGroup,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      headers: [
        { text: "教會", align: "center", sortable: false, value: "parmChurch" },
        { text: "奉獻編號", align: "center", sortable: false, value: "number" },
        { text: "小組", align: "center", sortable: false, value: "groupId" },
        { text: "姓名", align: "center", sortable: false, value: "name" },
        { text: "手機", align: "center", sortable: false, value: "phone" },
        {
          text: "奉獻日期",
          align: "center",
          sortable: false,
          value: "lastDedicationDate",
        },
        { text: "狀態", align: "center", sortable: false, value: "statusText" },
        {
          text: "編輯者",
          align: "center",
          sortable: false,
          value: "updateMemberText",
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],
      editMode: null,
      editingItem: {},
      deleteItem: {},
    };
  },
  watch: {
    addMode() {
      const addItem = this.dataList.find((item) => {
        return !item.id;
      });
      this.editingItem = addItem;
    },
  },
  computed: {
    groups() {
      return this.$store.state.group.group;
    },
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    formatChurch(val) {
      const currentChurch = this.clcList.find((item) => {
        return item.value === val;
      });
      if (currentChurch) {
        return currentChurch.text;
      } else {
        return val;
      }
    },
    handleUpdateGroupId(val) {
      this.editingItem["groupId"] = val;
    },
    groupName(val) {
      const group = this.groups.find((item) => {
        return item.group_id === val;
      });
      if (group) {
        return group.group_name;
      } else {
        return "-";
      }
    },
    edit(item) {
      this.editMode = "edit";
      this.editingItem = item;
    },
    change(item) {
      this.editMode = "change";
      this.editingItem = item;
    },
    cancelEditItem() {
      this.editingItem = {};
    },
    updateItem() {
      this.$emit("update", this.editingItem);
      this.editingItem = {};
    },
    changeItem() {
      this.$emit("change", this.editingItem);
      this.editingItem = {};
    },
    cancelAddItem(item) {
      this.$emit("cancelAddItem", item);
      this.editMode = null;
      this.editingItem = {};
    },
    addItem() {
      this.$emit("addItem", this.editingItem);
      this.editingItem = {};
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px;
}

.edit-component {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 80px;
}
.edit-date-input {
  border: 1px solid #5e6e7d;
  border-radius: 4px;
  padding: 4px 8px;
  max-width: 180px;
}
.checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
