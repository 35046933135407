<template lang="pug">
v-card(
  v-if="right.operate.allowQueryData || right.operate.allowAddData"
  class="pa-4"
  outlined
)
  v-form(@submit.prevent="submit")
    v-row(v-if="right.operate.allowQueryData")
      v-col(cols="6" md="3")
        v-select(
          v-model="searchPayload.parmChurch" 
          :items="clcList"
          label="教會"
          hide-details
        )
      v-col(cols="6" md="3")
        v-text-field(
          v-model="searchPayload.number" 
          label="奉獻編號"
          hide-details
        )
      v-col(cols="6" md="3")
        v-text-field(
          v-model="searchPayload.phone" 
          label="手機"
          hide-details
        )
      v-col(cols="6" md="3")
        v-text-field(
          v-model="searchPayload.name" 
          label="姓名"
          hide-details
        )
      v-col(cols="6" md="3")
        v-checkbox(
          v-model="searchPayload.isExpired" 
          label="查詢過期編號"
          hide-details
        )
      v-col(class="d-flex" cols="6" md="4" offset="5")
        v-btn(
          v-if="right.operate.allowQueryData"
          @click.stop="handleSearch"
          type="submit"
          class="ma-2 font-weight-bold text-button" dark color="primary")
          v-icon(dark) mdi-magnify
          span(class="font-weight-bold") 搜尋
        //- v-btn(
        //-   v-if="right.operate.allowQueryData"
        //-   @click.stop="clear"
        //-   class="ma-2 font-weight-bold text-button")
        //-   v-icon mdi-broom
        //-   span(class="font-weight-bold") 全部
        v-btn(
          v-if="right.operate.allowAddRow && !addMode"
          @click.stop="addDedicationNumber"
          class="ma-2 ml-auto font-weight-bold text-button" color="success")
          v-icon mdi-plus
          span(class="font-weight-bold") 新增
</template>

<script>
import GroupComponent from "@/components/common/group";
export default {
  name: "dedicationNumberSearchForm",
  components: {
    GroupComponent,
  },
  props: {
    addMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchPayload: {
        parmChurch: null,
        number: null,
        isExpired: false,
        phone: "",
        name: ""
      },
    };
  },
  computed: {
    clcList() {
      return this.$store.state.group.clcList.clcList;
    },
    right() {
      const currentPageRight = this.$store.state.auth.right.find((item) => { return item.router === this.$route.path })
      return currentPageRight
    },
  },
  methods: {
    submit() {
      this.$emit("search", this.searchPayload);
    },
    clear() {
      this.searchPayload = {
        parmChurch: null,
        number: null,
        isExpired: false,
        phone: "",
        name: ""
      };
      this.submit();
    },
    handleSearch() {
      const payload = {
        parmChurch:
          this.searchPayload.parmChurch && this.searchPayload.parmChurch !== ""
            ? this.searchPayload.parmChurch
            : null,
        number:
          this.searchPayload.number && this.searchPayload.number !== ""
            ? this.searchPayload.number
            : null,
        isExpired: this.searchPayload.isExpired,
        phone: this.searchPayload.phone,
        name: this.searchPayload.name
      };
      this.$emit("search", payload);
    },
    handleSearchGroup(val) {
      this.searchPayload.groupId = val;
    },
    addDedicationNumber() {
      this.$emit("add");
    },
  },
};
</script>
